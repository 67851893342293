import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    SimpleShowLayout,
    CreateButton,
    TopToolbar,
    ReferenceField,
    ReferenceInput,
    ReferenceArrayInput,
    NumberInput,
    ExportButton,
    TextField,
    TextInput,
    DateTimeInput,
    EditButton,
    DeleteButton,
    RichTextField,
    SelectInput,
    SelectArrayInput,
    TabbedForm,
    FormTab,
    DateField,
    ImageField,
    NumberField,
    BooleanInput,
    Pagination, FilterButton, ImageInput
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { ImportButton } from "react-admin-import-csv";
import CustomCurrencyField from "./CustomCurrencyField";
import {instanz} from "./stores";
const productFilter = [
    <TextInput source="name" label="Nach Name suchen" alwaysOn />,
    <TextInput source="barcode" label="Nach Barcode suchen" alwaysOn />,
    <ReferenceInput sort={{ field: 'name', order: 'ASC' }} perPage={50} label="Lieferant" source="supplier" reference="supplier" alwaysEmpty >
        <SelectInput optionText="name" />
    </ReferenceInput>,
    <ReferenceInput sort={{ field: 'name', order: 'ASC' }} perPage={50} label="Kategorien" source="category" reference="categories" alwaysEmpty >
        <SelectInput optionText="name" />
    </ReferenceInput>,

];
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const ListActions = (props) => {
    const { className, basePath } = props;
    return (
        <TopToolbar className={className}>
            <FilterButton basePath={basePath}/>
            <CreateButton basePath={basePath} />
            <ImportButton {...props} />
            <ExportButton basePath={basePath} />
        </TopToolbar>
    );
};
export const ProductList = (props) => (
    <List actions={<ListActions />} filters={productFilter} pagination={<PostPagination />} perPage={25} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <CustomCurrencyField source="price" />
            <TextField source="barcode" />
            <ReferenceField label="Lieferant" source="supplier" reference="supplier">
                <TextField source="name" />
            </ReferenceField>
            <ReferenceField label="Kategorie" source="category" reference="categories">
                <TextField source="name" />
            </ReferenceField>
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);

export const ProductShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField disabled source="id" />
            <TextField source="name" />
            <NumberField source="price" />
            <TextField source="barcode" />
            <RichTextInput source="Beschreibung" />
            <DateTimeInput disabled source="createdate" />
            <DateTimeInput disabled source="lastupdate" />
            <TextField source="createdby" />
            <TextField source="updatedby" />
            <RichTextField source="body" />
        </SimpleShowLayout>
    </Show>
);

export const ProductCreate = (props) => (
    <Create {...props} >
        <TabbedForm>
            <FormTab label="Übersicht">
                <TextInput source="name" label="Name des Produkts" />
                <ReferenceInput label="Lieferant" source="supplier" perPage={50} reference="supplier" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Kategorie" source="category" perPage={50} reference="categories" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                {instanz === "test" ?
                    <ImageInput source="imageUrl" label="Produktbild" accept="image/*">
                        <ImageField source="src" title="title"/>
                    </ImageInput> : <TextInput source="imageUrl" label="Bild-URL" />
                }
                <ImageField label="Produktbild" source="imageUrl" />
            </FormTab>
            <FormTab label="Produktdetails">
                <TextInput source="barcode" label="EAN-Code"  />
                <SelectInput source="taxCategory" label="MWST-Satz"
                             choices={[
                                 { id: '7.7', name: 'Normalsatz' },
                                 { id: '2.5', name: 'Reduzierter Satz' },
                                 { id: '3.7', name: 'Sondersatz' },
                                 { id: '0', name: 'Befreit von MwSt' },
                             ]}/>
                <BooleanInput source="showOnHomescreen" label="Auf Startbildschirm" helperText="Produkt auf Startseite anzeigen" />
                <BooleanInput source="showOnScreen" label="Manuelle Auswahl" helperText="Produkt in manueller Auswahl anzeigen" />
                <BooleanInput source="ageRestriction" label="Altersverifikation" helperText="Altersverifikation notwendig" />
                <BooleanInput source="showOnScale" label="Auf Waage" helperText="Produkt wird auf Waage angezeigt" />
                <BooleanInput source="saleStop" label="Verkaufstop" helperText="Das Produkt kann nicht gekauft werden" />
            </FormTab>
            <FormTab label="Verkauf">
                <h3>Verkaufsangaben:</h3>
                <NumberInput source="price" label="Verkaufspreis CHF" helperText="Preis in Rappen"/>
                <NumberInput source="depositProduct" label="Depot CHF" helperText="Betrag in Rappen" />
                <h3>Angebotsangaben:</h3>
                <NumberInput source="salePrice" label="Angebotspreis CHF" helperText="Angebotspreis in Rappen" />
                <NumberInput source="salePercentage" label="Rabatt in Prozent" />
                <DateTimeInput source="salePriceDateFrom" label="Angebot ab" />
                <DateTimeInput source="salePriceDateTo" label="Angebot bis" />
            </FormTab>
            <FormTab label="Einkauf">
                <NumberInput source="costPrice" label="Einstandspreis CHF" helperText="Preis in Rappen" />
                <NumberInput source="margin" label="Marge" helperText="Marge in Prozent" />
            </FormTab>
        </TabbedForm>
    </Create>
);

/* Parkplatz für nicht verwendete Felder:
                <RichTextInput source="description" />
                <TextInput source="subtitle" />
                <TextInput source="bundledProduct" />
                <BooleanInput source="deleted" />
                <BooleanInput source="weighByCustomer" />
                <TextInput source="referenceUnit" />
                <BooleanInput source="isDiscountable"  />
                <BooleanInput source="saleStop" />
                <TextInput source="scanSound" />
                <TextInput source="scanMessage" />
                <BooleanInput source="visibility" />
                <BooleanInput source="notForSale" />
                <BooleanInput source="outOfStock" />
                <NumberInput source="stock" />
 */

export const ProductEdit = (props) => (
    <Edit aside={<Aside />} {...props}>
        <TabbedForm>
            <FormTab label="Übersicht">
                <TextInput source="name" label="Name des Produkts" />
                <ReferenceInput label="Lieferant" source="supplier" perPage={50} reference="supplier" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput label="Kategorie" source="category" perPage={50} reference="categories" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectInput optionText="name" />
                </ReferenceInput>

                {instanz === "test" ?
                    <ImageInput source="imageUrl" label="Produktbild" accept="image/*">
                        <ImageField source="src" title="title"/>
                    </ImageInput> : <TextInput source="imageUrl" label="Bild-URL" />
                }
                <ImageField label="Produktbild" source="imageUrl" />
            </FormTab>
            <FormTab label="Produktdetails">
                <TextInput source="barcode" label="EAN-Code"  />
                <SelectInput source="taxCategory" label="MWST-Satz"
                             choices={[
                                 { id: '7.7', name: 'Normalsatz' },
                                 { id: '2.5', name: 'Reduzierter Satz' },
                                 { id: '3.7', name: 'Sondersatz' },
                                 { id: '0', name: 'Befreit von MwSt' },
                             ]}/>
                <BooleanInput source="showOnHomescreen" label="Auf Startbildschirm" helperText="Produkt auf Startseite anzeigen" />
                <BooleanInput source="showOnScreen" label="Manuelle Auswahl" helperText="Produkt in manueller Auswahl anzeigen" />
                <BooleanInput source="ageRestriction" label="Altersverifikation" helperText="Altersverifikation notwendig" />
                <BooleanInput source="showOnScale" label="Auf Waage" helperText="Produkt wird auf Waage angezeigt" />
                <BooleanInput source="saleStop" label="Verkaufsstop" helperText="Das Produkt kann nicht gekauft werden" />
                <ReferenceArrayInput label="Verkaufsstop in Stores" helperText="In diesen Stores können die Produkte nicht gekauft werden." source="hideStore" perPage={50} reference="stores" sort={{ field: 'name', order: 'ASC' }}>
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            </FormTab>
            <FormTab label="Verkauf">
                <h3>Verkaufsangaben:</h3>
                <NumberInput source="price" label="Verkaufspreis CHF" helperText="Preis in Rappen"/>
                <NumberInput source="depositProduct" label="Depot CHF" helperText="Betrag in Rappen"/>
                <h3>Angebotsangaben:</h3>
                <NumberInput source="salePrice" label="Angebotspreis CHF" helperText="Angebotspreis in Rappen"/>
                <NumberInput source="salePercentage" label="Rabatt in Prozent"/>
                <DateTimeInput source="salePriceDateFrom" label="Angebot ab"/>
                <DateTimeInput source="salePriceDateTo" label="Angebot bis"/>
            </FormTab>
            <FormTab label="Einkauf">
                <NumberInput source="costPrice" label="Einstandspreis CHF" helperText="Preis in Rappen"/>
                <NumberInput source="margin" label="Marge" helperText="Marge in Prozent"/>
            </FormTab>
        </TabbedForm>
    </Edit>
);

const Aside = (props) => {
    return (
        <div style={{ width: 300, margin: '1em' }}>
            <SimpleShowLayout>
                <h3>Verlauf </h3>
                <DateField disabled source="createdate" label="Erstellungsdatum" showTime />
                <DateField disabled source="lastupdate" label="Änderungsdatum" showTime />
                <TextField disabled source="createdby" label="Erstellt von" />
                <TextField disabled source="updatedby" label="Zuletzt geändert" />
            </SimpleShowLayout>
        </div>
    );
}
// const perm = authProvider.getPermissions();
// console.log(perm);
