// in src/Comments.js
import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    BooleanInput,
    Edit, SimpleShowLayout,
    SimpleForm,
    TextField,
    TextInput,
    EditButton,
    DeleteButton,
    SelectInput, ReferenceField, ReferenceInput, BooleanField, ImageInput, ImageField
} from "react-admin";
import {instanz} from "./stores";

export const CategoriesList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <BooleanField source="showOnScreen" label="Anzeige in manueller Auswahl" />
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);


/*
Parkplatz für nicht verwendete Felder:
            <ReferenceInput label="Überkategorie" source="category" reference="categories">
                <SelectInput optionText="name" />
            </ReferenceInput>
 */

export const CategoriesCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput label="Titelform" source="id" />
            <TextInput source="name" />
            <BooleanInput source="showOnScreen" label="Manuelle Auswahl" helperText="In manueller Auswahl anzeigen"/>
            {instanz === "test" ?
                <ImageInput source="imageUrl" label="Bild der Kategorie" accept="image/*">
                    <ImageField source="src" title="title"/>
                </ImageInput> : <TextInput source="imageUrl" label="Bild-URL" />
            }

        </SimpleForm>
    </Create>
);

export const CategoriesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Titelform" source="id" disabled />
            <TextInput source="name" />
            <BooleanInput source="showOnScreen" label="Manuelle Auswahl" helperText="In manueller Auswahl anzeigen" />
            {instanz === "test" ?
                <ImageInput source="imageUrl" label="Bild der Kategorie" accept="image/*">
                    <ImageField source="src" title="title"/>
                </ImageInput> : <TextInput source="imageUrl" label="Bild-URL" />
            }
        </SimpleForm>
    </Edit>
);
