// in src/Comments.js
import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    DateInput,
    DateField,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    TextInput,
    NumberField,
    EditButton,
    DeleteButton, SelectArrayInput, ReferenceArrayInput, NumberInput
} from "react-admin";

export const DiscountsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="barcode" />
            <DateField source="validUntil" />
            <TextField source="useCases" />
            <NumberField source="percentage" />
            <TextField source="description" />
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);

export const DiscountsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="barcode" />
            <DateField source="validUntil" />
            <TextField source="useCases" />
            <NumberField source="percentage" />
            <TextField source="description" />
        </SimpleShowLayout>
    </Show>
);


export const DiscountsCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="barcode" />
            <DateInput source="validUntil" />
            <TextInput source="useCases" />
            <NumberInput source="percentage" />
            <TextInput source="description" />
            <ReferenceArrayInput source="products" reference="products">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="categories" reference="categories">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);

export const DiscountsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="barcode" />
            <DateInput source="validUntil" />
            <TextInput source="useCases" />
            <NumberInput source="percentage" />
            <TextInput source="description" />
            <ReferenceArrayInput source="products" reference="products">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput source="categories" reference="categories">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
