// in src/Comments.js
import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    FileInput,
    FileField,
    BooleanInput,
    TextInput,
    EditButton,
    DeleteButton,
    ArrayInput,
    SimpleFormIterator,
    ImageInput,
    ImageField,
} from "react-admin";

let host = window.location.host;
let parts = host.split(".");
export let instanz = "toggenburgshop";



if (parts.length >= 2) {
    instanz = parts[0];
}

export const StoreList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);

export const StoreShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="name" />
        </SimpleShowLayout>
    </Show>
);

const storeDefaultValues = () => ({ customMessage: "Herzlichen Dank für Deinen Einkauf!" });

export const StoreCreate = (props) => (
    <Create {...props} >
        <SimpleForm initialValues={storeDefaultValues}>
            <TextInput source="name" />
            <TextInput source="address" label="Strasse und Nr."  />
            <TextInput source="zipCode" label="PLZ" />
            <TextInput source="city" label="Stadt" />
            <TextInput source="vatNumber" label="MWST-Nr."  />
            {instanz === "test" ?
                <ImageInput source="logoUrl" label="Logo-URL" helperText="Logo für Beleg" accept="image/*">
                    <ImageField source="src" title="title"/>
                </ImageInput> : <TextInput source="logoUrl" label="Logo-URL" helperText="Logo für Beleg"/>
            }
            <ImageInput source="backgroundImageScale" label="Hintergrundbild Waage" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <TextInput label="Nachricht auf Kundenbeleg" source="customMessage"  />
            <TextInput source="defaultPicture" label="Standardbild Warenkorb" helperText="Platzhalterbild im Warenkorb" />
            <TextInput source="backgroundImage" label="Hintergrundbild Startbildschirm" />
            <TextInput source="backgroundVideo" label="Hintergrundvideo Startbildschirm" />
            <ImageInput source="backgroundImageCart" label="Hintergrundbild Warenkorb" helperText="Bild welches im Warenkorb als Hintergrund angezeigt wird" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <BooleanInput source="showPictures" label="Anzeige Produktbilder" helperText="Produktbilder werden im Warenkorb angezeigt" />
            <BooleanInput source="paymentPaytec" label="Zahlungsmittel PayTec" helperText="Zahlung via PayTec möglich" />
            <BooleanInput source="paymentCash" label="Zahlungsmittel Bargeld" helperText="Zahlung via Bargeld möglich" />
            <BooleanInput source="showSupplier" label="Anzeige Lieferant" helperText="Lieferanten der Produkte auf Beleg anzeigen" />
        </SimpleForm>
    </Create>
);

export const StoreEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="address" label="Strasse und Nr."  />
            <TextInput source="zipCode" label="PLZ" />
            <TextInput source="city" label="Stadt" />
            <TextInput source="vatNumber" label="MWST-Nr."  />
             {instanz === "test" ?
                <ImageInput source="logoUrl" label="Logo-URL" helperText="Logo für Beleg" accept="image/*">
                    <ImageField source="src" title="title"/>
                </ImageInput> : <TextInput source="logoUrl" label="Logo-URL" helperText="Logo für Beleg"/>
            }
            <ImageInput source="backgroundImageScale" label="Hintergrundbild Waage" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <TextInput label="Nachricht auf Kundenbeleg" source="customMessage"  />
            <TextInput source="backgroundImage" label="Hintergrundbild Startbildschirm" />
            <TextInput source="backgroundVideo" label="Hintergrundvideo Startbildschirm" />
            <ImageInput source="backgroundImageCart" label="Hintergrundbild Warenkorb" helperText="Bild welches im Warenkorb als Hintergrund angezeigt wird" accept="image/*">
                <ImageField source="src" title="title"/>
            </ImageInput>
            <TextInput source="defaultPicture" label="Standardbild Warenkorb" helperText="Platzhalterbild im Warenkorb" />
            <BooleanInput source="showPictures" label="Anzeige Produktbilder" helperText="Produktbilder werden im Warenkorb angezeigt" />
            <BooleanInput source="paymentPaytec" label="Zahlungsmittel PayTec" helperText="Zahlung via PayTec möglich" />
            <BooleanInput source="paymentCash" label="Zahlungsmittel Bargeld" helperText="Zahlung via Bargeld möglich" />
            <BooleanInput source="showSupplier" label="Anzeige Lieferant" helperText="Lieferanten der Produkte auf Beleg anzeigen" />
        </SimpleForm>
    </Edit>
);
