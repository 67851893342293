import * as React from "react";
import {  PaymentmethodsShow, PaymentmethodsCreate, PaymentmethodsEdit } from "./paymentmethods";
import { StoreList, StoreShow, StoreCreate, StoreEdit } from "./stores";
import { ProductList,  ProductCreate, ProductEdit } from './products'
import { BundleShow, BundleCreate, BundleEdit } from './bundles'
import { OrderList, OrderShow, OrderCreate, OrderEdit } from './orders'
import { AnalyticsList } from './Analytics/AnalyticsDataList'
import { SupplierList,  SupplierCreate, SupplierEdit } from './supplier'
import { CategoriesList,  CategoriesCreate, CategoriesEdit } from './categories'
import { GiftcardsList,  GiftcardsCreate, GiftcardsEdit } from './giftcards'
import { DiscountsCreate, DiscountsEdit } from './discounts'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import  customGermanMessages  from './i18n/de';
import  customEnglishMessages  from './i18n/en';
import {Admin, Resource, resolveBrowserLocale} from "react-admin";
import customRoutes from './customRoutes';
import { theme } from "./theme";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  FirebaseDataProvider,
  FirebaseAuthProvider
} from "react-admin-firebase";
import { ShoppingCart, Payment, Store, Receipt, Category, CardGiftcard, LocalShipping, AttachMoney, BarChart, TableChart } from '@material-ui/icons';
import CustomLoginPage from './CustomLoginPage';
import Dashboard from './welcome_dashboard/Dashboard';
import Layout from './Layout';
import {firebaseConfig as config} from './FIREBASE_CONFIG';
import {SupplierAnalyticsList} from "./SupplierAnalytics/SupplierAnalyticsDataList";





const messages = {
    'de': customGermanMessages,
    'en': customEnglishMessages,
};
const i18nProvider = polyglotI18nProvider(
    locale => messages[locale] ? messages[locale] : messages.en,
    resolveBrowserLocale()
);
//get subdomain
let host = window.location.host;
let parts = host.split(".");
export let instanz = "toggenburgshop";



if (parts.length >= 2) {
    instanz = parts[0];
}


const options = {
    rootRef: "instance/"+ instanz  +"/",
    softDelete: true,
    logging: false,
}

export const dataProvider = FirebaseDataProvider(config, options);
export const authProvider = FirebaseAuthProvider(config, options);

// add to <Admin> to change AppBar:
//            layout={Layout}



class App extends React.Component {
  render() {
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Admin
            theme={theme}
            locale={"de-ch"}
            dashboard={Dashboard}
            loginPage={CustomLoginPage}
            dataProvider={dataProvider}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            >
         <Resource
            name="products"
            icon={ShoppingCart}
            list={ProductList}
            create={ProductCreate}
            edit={ProductEdit}
        />
            <Resource
                name="orders"
                icon={Receipt}
                list={OrderList}
                show={OrderShow}
                edit={OrderEdit}
            />
            <Resource
                name="analytics"
                icon={BarChart}
                list={AnalyticsList}

            />
            <Resource
                name="supplieranalytics"
                icon={TableChart}
                list={SupplierAnalyticsList}
            />
            <Resource
                name="paymentmethods"
                icon={Payment}
                label="Payment Methods"
                show={PaymentmethodsShow}
                create={PaymentmethodsCreate}
                edit={PaymentmethodsEdit}
            />
            <Resource
                name="stores"
                icon={Store}
                list={StoreList}
                show={StoreShow}
                create={StoreCreate}
                edit={StoreEdit}
            />

            <Resource
            name="bundles"
            icon={Store}
            show={BundleShow}
            create={BundleCreate}
            edit={BundleEdit}
        />
            <Resource
                name="supplier"
                icon={LocalShipping}
                list={SupplierList}
                create={SupplierCreate}
                edit={SupplierEdit}
            />
            <Resource
            name="categories"
            icon={Category}
            list={CategoriesList}
            create={CategoriesCreate}
            edit={CategoriesEdit}
        />
            <Resource
                name="giftcards"
                icon={CardGiftcard}
                list={GiftcardsList}
                create={GiftcardsCreate}
                edit={GiftcardsEdit}
            />
            <Resource
                name="discounts"
                icon={AttachMoney}
                create={DiscountsCreate}
                edit={DiscountsEdit}
            />
            <Resource
                name="users"
                icon={AttachMoney}
                create={DiscountsCreate}
                edit={DiscountsEdit}
            />





        </Admin>
        </LocalizationProvider>
    );
  }
}

export default App;