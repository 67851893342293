// In theme.js
import { defaultTheme } from "react-admin";
import { createTheme } from '@mui/material/styles';
import merge from "lodash/merge";

//get subdomain
let host = window.location.host;
let parts = host.split(".");
export let instanz = "toggenburgshop";



if (parts.length >= 2) {
    instanz = parts[0];
}

export const color = () => {
   switch (instanz) {
       case "toggenburgshop":
           return "#294257";
       case "9620":
           return "#FFC714"
       case "test":
           return "#ff0000";
       default:
           return "#3e9cb0"
   }
}


export const theme = createTheme(
    merge({}, defaultTheme, {
        palette: {
            // Your theme goes here
            // Write the following code to have an orange app bar. We'll explain it later in this article.
            secondary: {
                main: color(), // scango.ch-green
            },
        }
    })
);