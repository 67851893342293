import { useGetOne } from 'react-admin';
import * as React from "react";


const CategoryChipField = (record) => {
    const { data, loading, error } = useGetOne('categories', record.record);
    if (loading) { return <p>Loading... </p>; }
    if (error) { return <p>Kategorie nicht gefunden</p>; }
    return data ? data.name : '';

};
export default CategoryChipField;