// in src/Comments.js
import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    TextInput,
    EditButton,
    DeleteButton,
    SelectInput,
} from "react-admin";

export const SupplierList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <TextField source="city" label="Ort" />
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);

export const SupplierShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="city" label="Ort" />
        </SimpleShowLayout>
    </Show>
);


export const SupplierCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="street" label="Strasse und Nr. " />
            <TextInput source="plz" label="PLZ" />
            <TextInput source="city" label="Ort" />
            <TextInput source="email" label="E-Mail" />
            <TextInput source="phone" label="Telefon" />
            <TextInput source="contactPerson" label="Ansprechperson" />
            <TextInput source="contactPhone" label="Direktwahl Ansprechperson" />
            <TextInput source="logoUrl" label="Logo-URL" />
            <SelectInput helperText="Tägliches Mail mit Produktverkäufen des Produzenten" label="Täglicher Verkaufsbericht" source="dailyMail" choices={[
                { id: "nomail", name: "keine E-Mail" },
                { id: "00", name: '00:00 Uhr' },
                { id: "01", name: '1:00 Uhr' },
                { id: "02", name: '2:00 Uhr' },
                { id: "03", name: '3:00 Uhr' },
                { id: "04", name: '4:00 Uhr' },
                { id: "05", name: '5:00 Uhr' },
                { id: "06", name: '6:00 Uhr' },
                { id: "07", name: '7:00 Uhr' },
                { id: "08", name: '8:00 Uhr' },
                { id: "09", name: '9:00 Uhr' },
                { id: "10", name: '10:00 Uhr' },
                { id: "11", name: '11:00 Uhr' },
                { id: "12", name: '12:00 Uhr' },
                { id: "13", name: '13:00 Uhr' },
                { id: "14", name: '14:00 Uhr' },
                { id: "15", name: '15:00 Uhr' },
                { id: "16", name: '16:00 Uhr' },
                { id: "17", name: '17:00 Uhr' },
                { id: "18", name: '18:00 Uhr' },
                { id: "19", name: '19:00 Uhr' },
                { id: "20", name: '20:00 Uhr' },
                { id: "21", name: '21:00 Uhr' },
                { id: "22", name: '22:00 Uhr' },
                { id: "23", name: '23:00 Uhr' },
            ]} />
        </SimpleForm>
    </Create>
);

export const SupplierEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextField source="id" disabled/>
            <TextInput source="name" />
            <TextInput source="street" label="Strasse und Nr. " />
            <TextInput source="plz" label="PLZ" />
            <TextInput source="city" label="Ort" />
            <TextInput source="email" label="E-Mail" />
            <TextInput source="phone" label="Telefon" />
            <TextInput source="contactPerson" label="Ansprechperson" />
            <TextInput source="contactPhone" label="Direktwahl Ansprechperson" />
            <TextInput source="logoUrl" label="Logo-URL" />
            <SelectInput helperText="Tägliches Mail mit Produktverkäufen des Produzenten" label="Täglicher Verkaufsbericht" source="dailyMail" choices={[
                { id: "nomail", name: "keine E-Mail" },
                { id: "00", name: '00:00 Uhr' },
                { id: "01", name: '1:00 Uhr' },
                { id: "02", name: '2:00 Uhr' },
                { id: "03", name: '3:00 Uhr' },
                { id: "04", name: '4:00 Uhr' },
                { id: "05", name: '5:00 Uhr' },
                { id: "06", name: '6:00 Uhr' },
                { id: "07", name: '7:00 Uhr' },
                { id: "08", name: '8:00 Uhr' },
                { id: "09", name: '9:00 Uhr' },
                { id: "10", name: '10:00 Uhr' },
                { id: "11", name: '11:00 Uhr' },
                { id: "12", name: '12:00 Uhr' },
                { id: "13", name: '13:00 Uhr' },
                { id: "14", name: '14:00 Uhr' },
                { id: "15", name: '15:00 Uhr' },
                { id: "16", name: '16:00 Uhr' },
                { id: "17", name: '17:00 Uhr' },
                { id: "18", name: '18:00 Uhr' },
                { id: "19", name: '19:00 Uhr' },
                { id: "20", name: '20:00 Uhr' },
                { id: "21", name: '21:00 Uhr' },
                { id: "22", name: '22:00 Uhr' },
                { id: "23", name: '23:00 Uhr' },
            ]} />
        </SimpleForm>
    </Edit>
);
