import {
    useDataProvider, useGetOne,
    useVersion,
} from "react-admin";
import Box from '@mui/material/Box';
import {useCallback, useEffect, useState} from "react";
import {subDays} from "date-fns";
import * as React from "react";
import {DataGrid, GridToolbar,} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import {Chip, gridClasses} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SupplierChipField from "./SupplierChipField";
import CategoryChipField from "./CategoryChipField";
import DollarIcon from "@material-ui/icons/AttachMoney";
import CardWithIcon from "../welcome_dashboard/CardWithIcon";


const header = [
    {
        field: 'imageUrl',
        headerName: 'Image',
        type: 'image',
        width: 70,
        hide: true,
        editable: false,
        renderCell: (params) => (
            <Avatar src={`${params.value}`} />
        )
    },
    {
        field: 'id',
        headerName: 'ID',
        type: 'text',
        width: 210,
        hide: true,
        editable: false,
    },
    {
        field: 'quantity',
        headerName: 'Anzahl',
        type: 'number',
        width: 120,
        editable: false,
    },
    { field: 'title', headerName: 'Name', width: 300 },
    {
        field: 'supplier',
        headerName: 'Lieferant',
        flex: 0.8,
        minWidth: 200,
        editable: false,
        renderCell: (params) => (
            <Chip
                label={<SupplierChipField record={params.value} />}
                component="a"
                href={`/#/supplier/${params.row.supplier}`}
                variant="outlined"
                clickable
            />
        )
    },
    {
        field: 'category',
        headerName: 'Kategorie',
        flex: 0.8,
        minWidth: 200,
        editable: false,
        renderCell: (params) => (
            <Chip
                label={<CategoryChipField record={params.value} />}
                component="a"
                href={`/#/categories/${params.row.supplier}`}
                variant="outlined"
                clickable
            />
        )
    },
    {
        field: 'orderCount',
        headerName: 'Anzahl Bestellungen',
        type: 'number',
        width: 200,
        editable: false,
    },
    {
        field: 'price',
        headerName: 'Preis inkl. MWST',
        type: 'number',
        width: 150,
        editable: false,
        valueFormatter: ({ value }) => `${value / 100}`,
    },

    {
        field: 'netPrice',
        headerName: 'Preis ohne MWST',
        type: 'number',
        width: 150,
        editable: false,
        hide: true,
        valueFormatter: ({ value }) => `${value / 100}`,
    },
    {
        field: 'tax',
        headerName: 'MWST',
        type: 'number',
        width: 120,
        editable: false,
        hide: true,
        valueFormatter: ({ value }) => `${value / 100}`,
    },
    {
        field: 'costPrice',
        headerName: 'Einstandspreis',
        type: 'number',
        width: 150,
        editable: true,
        valueFormatter: ({ value }) => `${value / 100}`,

    },
    {
        field: 'margin',
        headerName: 'Marge',
        type: 'number',
        width: 150,
        editable: true,
        valueFormatter: ({ value }) => `${value / 100}`,
    },
    {
        field: 'deposit',
        headerName: 'Deposit',
        type: 'number',
        width: 110,
        editable: false,
        hide: true,
        valueFormatter: ({ value }) => `${value / 100}`,
        renderCell: (params) => (
            <div>CHF {params.value.toFixed(2) / 100} </div>
        )
    },
    {
        field: 'taxRate',
        headerName: 'Tax Rate',
        type: 'number',
        width: 110,
        editable: false,
        hide: true,
        valueFormatter: ({ value }) => `${value / 100}`,
        renderCell: (params) => (
            <div>{params.value.toFixed(2) * 100} % </div>
        )
    },


];

export const AnalyticsList = () => {
    const aWeekAgo = subDays(new Date(), 7);
    aWeekAgo.setHours(0, 0, 0, 0);
    const today = new Date();
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [start, setStart] = useState(new Date(aWeekAgo));
    const [end, setEnd] = useState(new Date(today));
    const [orderData, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [total, setTotal] = useState([]);
    const [orderCount, setOrderCount] = useState(0);
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'quantity',
            sort: 'desc',
        },
    ]);

    const fetchOrders = useCallback(async (start, end) => {
        setData([]);
        setTotal([]);
        setOrderCount(0);
        setLoading(true);

        const recentOrders = await dataProvider.getList(
            "orders",
            {
                filter: { collectionQuery: c => c.where('orderDate', '>=', start).where('orderDate', '<=', end), paymentState: "successful" },
                sort: { field: 'orderDate', order: 'DESC' },
                pagination: { page: 1, perPage: 100000 },
            })
            .then(({ data, total }) => {
                const aggregations = data.reduce((acc, curr) =>
                    acc.concat(curr.products), []
                );
                setOrderCount(total);
                const totals = aggregations.reduce((acc, curr) => {
                    acc.quantity += curr.quantity;
                    acc.price += curr.price;
                    acc.netPrice += curr.netPrice;
                    acc.tax += curr.tax;
                    acc.deposit += curr.deposit;
                    acc.costPrice += curr.costPrice;
                    if (curr.costPrice) {
                        acc.margin += curr.price - curr.costPrice;
                    }
                    return acc
                } , {quantity: 0, price: 0, netPrice: 0, tax: 0, deposit: 0, costPrice: 0, margin: 0});
                setTotal(totals);

                const soldProducts = aggregations.reduce((acc, curr) => {
                    if (curr.productId.slice(0, 6) === "PPPPP_") {
                        curr.productId = curr.productId.slice(6, -18)
                    }
                    const product = acc.find(p => p.productId === curr.productId);
                    if (product) {
                        product.quantity += curr.quantity;
                        product.price += curr.price;
                        product.netPrice += curr.netPrice;
                        product.tax += curr.tax;
                        product.deposit += curr.deposit;
                        product.costPrice += curr.costPrice;
                        product.orderCount += 1;
                        if (curr.costPrice) {
                            product.margin += curr.price - curr.costPrice;
                        }

                    } else {
                        curr.id = curr.productId;
                        if (curr.costPrice) {
                            curr.margin = curr.price - curr.costPrice;
                        }
                        curr.orderCount = 1
                        acc.push(curr);
                    }
                    return acc;
                }, []);
                console.log(soldProducts)
                setData(soldProducts);
            setLoading(false);})
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [dataProvider]);
    useEffect(() => {
        fetchOrders(start, end);
    }, [version, start, end, fetchOrders]);


    function handleChangeStart(e) {
        const newStart = new Date(e);
        newStart.setHours(0, 0, 0, 0);
        setStart(newStart);
    }
    function handleChangeEnd(e) {
        const newEnd = new Date(e);
        newEnd.setHours(23, 59, 59, 999);
        setEnd(newEnd);
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div>

                    <Box sx={{ display: 'flex', marginTop: 2, marginBottom: 2, gap: 2}}>

                            <DatePicker
                                sx={{marginRight: 2}}
                                label="Von Datum"
                                value={start}
                                inputFormat="dd/MM/yyyy"
                                onChange={handleChangeStart}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        <DatePicker
                            label="bis zu Datum"
                            value={end}
                            inputFormat="dd/MM/yyyy"
                            onChange={handleChangeEnd}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>
                    <Box sx={{ display: 'flex', marginTop: 2, marginBottom: 2, gap: 2}}>
                        <Box sx={{width: 230}}>
                            <CardWithIcon
                                to="/orders"
                                icon={DollarIcon}
                                title={"Anzahl Bestellungen"}
                                subtitle={orderCount}
                            />
                        </Box>
                    <Box sx={{width: 230}}>
                            <CardWithIcon
                                to="/orders"
                                icon={DollarIcon}
                                title={"Umsatz inkl. MWST"}
                                subtitle={ "CHF " + (total.price / 100).toFixed(2)}
                            />
                        </Box>
                        <Box sx={{width: 230}}>
                            <CardWithIcon
                                to="/orders"
                                icon={DollarIcon}
                                title={"MWST"}
                                subtitle={ "CHF " + (total.tax / 100).toFixed(2)}
                            />
                        </Box>
                        <Box sx={{width: 230}}>
                            <CardWithIcon
                                to="/orders"
                                icon={DollarIcon}
                                title={"Umsatz exkl. MWST"}
                                subtitle={ "CHF " + (total.netPrice / 100).toFixed(2)}
                            />
                        </Box>
                        <Box sx={{width: 230}}>
                        <CardWithIcon
                            to="/orders"
                            icon={DollarIcon}
                            title={"Verkaufte Produkte"}
                            subtitle={total.quantity}
                        />
                        </Box>

                        <Box sx={{width: 230}}>
                        {total.margin > 0 && <CardWithIcon
                            to="/orders"
                            icon={DollarIcon}
                            title={"Marge"}
                            subtitle={"CHF " + total.margin / 100}
                        />}
                        </Box>
                    </Box>



                    <Box component={Paper} >
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            loading={loading}
                            error={error}
                            rows={orderData}
                            columns={header}
                            sortingOrder={['desc', 'asc']}
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            autoHeight
                            checkboxSelection
                            disableSelectionOnClick
                        >

                        </DataGrid>

                    </Box>
                </div>



        </LocalizationProvider>

    );
};
