import React, { useState, useEffect, useCallback, CSSProperties } from 'react';
import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import { subDays } from 'date-fns';
import MonthlyRevenue from './MonthlyRevenue';
import NbNewOrders from './NbNewOrders';
import ProductSales from './ProductSales';
import OrderChart from './OrderChart';
import ProductQuantity from './ProductQuantity';
import firebase from "firebase/compat/app";
import { Supplier, Orders, Review, Products } from '../types';
import DollarIcon from "@material-ui/icons/AttachMoney";
import CardWithIcon from "./CardWithIcon";

interface OrderStats {
    revenue: number;
    revenuePerCustomer: number;
    revenuePerDay: number;
    nbNewOrders: number;
    revenueToday: number;
    supplierStats: Supplier[];
    pendingOrders: Orders[];
    productSales: Products[];
}

interface SupplierData {
    [key: string]: Supplier;
}

interface State {
    nbNewOrders?: number;
    nbPendingReviews?: number;
    supplierStats?: Supplier[];
    pendingOrders?: Orders[];
    supplierStatsData?: SupplierData;
    pendingReviews?: Review[];
    pendingReviewsCustomers?: SupplierData;
    recentOrders?: Orders[];
    revenue?: string;
    revenueToday?: string;
    revenuePerCustomer?: string;
    revenuePerDay?: string;
    productSales?: Products[];
}

const styles = {
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;
const VerticalSpacer = () => <span style={{ height: '1em' }} />;

const Dashboard = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const isSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('md')
    );

    const fetchOrders = useCallback(async () => {
        const aWeekAgo = subDays(new Date(), 7);
        aWeekAgo.setHours(0, 0, 0, 0);
        const today = new Date();
        const { data: recentOrders } = await dataProvider.getList<Orders>(
            'orders',
            {
                filter: { collectionQuery: (c: firebase.firestore.CollectionReference) => c.where('paymentState', '==', 'successful').where('orderDate', '>=', aWeekAgo).where('orderDate', '<=', today) },
                sort: { field: 'orderDate', order: 'DESC' },
                pagination: { page: 1, perPage: 10000 },
            }

        );

        const aggregations = recentOrders
            .reduce(
                (stats: OrderStats, order) => {
                        const oD:Date = new Date(order.orderDate);
                        const tD:Date = new Date(today.setHours(0, 0, 0, 0));
                        if (oD >= tD) {
                            stats.revenueToday += (order.price / 100 );
                        }
                        stats.revenue += (order.price / 100 );
                        stats.nbNewOrders++;
                        stats.pendingOrders.push(order);
                        order.products.forEach(product => {
                            const existingProduct = stats.productSales.find(
                                p => p.productId === product.productId
                            );
                            if (existingProduct) {
                                existingProduct.quantity += product.quantity;
                                existingProduct.price += product.price;
                            } else {
                                stats.productSales.push({
                                    ...product,
                                    quantity: product.quantity,
                                });
                            }
                        });
                    return stats;
                },

                {
                    supplierStats: [],
                    revenuePerCustomer: 0,
                    revenuePerDay: 0,
                    revenue: 0,
                    revenueToday: 0,
                    nbNewOrders: 0,
                    pendingOrders: [],
                    productSales: [],
                }
            );

        setState(state => ({
            ...state,
            recentOrders,
            revenuePerDay: ((aggregations.revenue - aggregations.revenueToday) / 7).toLocaleString(undefined, {
                style: 'currency',
                currency: 'CHF',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
            }),
            revenueToday: aggregations.revenueToday.toLocaleString(undefined, {
                style: 'currency',
                currency: 'CHF',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
            }),
            revenuePerCustomer: (aggregations.revenue / aggregations.nbNewOrders).toLocaleString(undefined, {
                style: 'currency',
                currency: 'CHF',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
            }),
            revenue: (aggregations.revenue - aggregations.revenueToday).toLocaleString(undefined, {
                style: 'currency',
                currency: 'CHF',
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                useGrouping: false,
            }),
            nbNewOrders: aggregations.nbNewOrders,
            pendingOrders: aggregations.pendingOrders,
            productSales: aggregations.productSales,
        }));
        const { data: supplier } = await dataProvider.getMany<Supplier>(
            'supplier',
            {
                ids: aggregations.productSales.map(
                    (products: Products) => products.supplier),
            }
        );
        setState(state => ({
            ...state,
            supplierStatsData: supplier.reduce(
                (prev: SupplierData, supplier) => {
                    prev[supplier.id] = supplier; // eslint-disable-line no-param-reassign
                    return prev;
                },
                {}
            ),
        }));
    }, [dataProvider]);


    useEffect(() => {
        fetchOrders();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const {
        nbNewOrders,
        nbPendingReviews,
        pendingOrders,
        supplierStatsData,
        pendingReviews,
        pendingReviewsCustomers,
        revenue,
        revenueToday,
        revenuePerCustomer,
        revenuePerDay,
        recentOrders,
        productSales,
    } = state;
    return isXSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
            </div>
            <div style={styles.flex}>
                <MonthlyRevenue value={revenue} />
                <Spacer />
                <NbNewOrders value={nbNewOrders} />
            </div>
            <Spacer />
            <div style={styles.singleCol}>

                <div style={styles.flex}>
                    <CardWithIcon
                        to="/orders"
                        icon={DollarIcon}
                        title={"Heutiger Umsatz"}
                        subtitle={revenueToday}
                    />
                    <Spacer />
                    <CardWithIcon
                        to="/orders"
                        icon={DollarIcon}
                        title={"Ø Umsatz pro Tag"}
                        subtitle={revenuePerDay}
                    />
                </div>
            </div>
            <div style={styles.singleCol}>
                <OrderChart orders={recentOrders} />
            </div>
        </div>
    ) : isSmall ? (
        <div style={styles.flexColumn as CSSProperties}>
            <div style={styles.singleCol}>
            </div>
            <div style={styles.flex}>
                <MonthlyRevenue value={revenue} />
                <Spacer />
                <NbNewOrders value={nbNewOrders} />
            </div>
            <Spacer />
            <div style={styles.singleCol}>

            <div style={styles.flex}>
                <CardWithIcon
                    to="/orders"
                    icon={DollarIcon}
                    title={"Heutiger Umsatz"}
                    subtitle={revenueToday}
                />
                <Spacer />
                <CardWithIcon
                    to="/orders"
                    icon={DollarIcon}
                    title={"Ø Umsatz pro Tag"}
                    subtitle={revenuePerDay}
                />
            </div>
                </div>
            <div style={styles.singleCol}>
                <OrderChart orders={recentOrders} />
            </div>
        </div>
    ) : (
        <>
            <div style={styles.flex}>
                <div style={styles.leftCol}>
                    <div style={styles.flex}>
                        <MonthlyRevenue value={revenue} />
                        <Spacer />
                        <NbNewOrders value={nbNewOrders} />
                    </div>
                    <div style={styles.singleCol}>
                        <OrderChart orders={recentOrders} />
                    </div>
                </div>
                <div style={styles.rightCol}>
                    <div style={styles.flex}>
                        <CardWithIcon
                            to="/orders"
                            icon={DollarIcon}
                            title={"Heutiger Umsatz"}
                            subtitle={revenueToday}
                        />
                            <Spacer />
                        <CardWithIcon
                            to="/orders"
                            icon={DollarIcon}
                            title={"Ø Umsatz pro Tag"}
                            subtitle={revenuePerDay}
                        />
                        </div>
                    <div style={styles.singleCol}>
                        <div style={styles.flex}>
                            <ProductQuantity products={productSales} />
                            <Spacer />
                            <ProductSales products={productSales} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Dashboard;

/*
<Spacer />
<SupplierSales supplier={} />
export default () => (
    <OrderTotals />
);

 */