import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
    root: {
        backgroundColor: "pink"
    }
});

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <AppBar {...props} classes={classes.root} elevation={1} /*userMenu={<CustomUserMenu />} */>
            <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
                id="react-admin-title"
            />

            <span className={classes.spacer} />
        </AppBar>
    );
};

export default CustomAppBar;
