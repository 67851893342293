// in src/Comments.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {CreateButton, ExportButton, TopToolbar} from 'react-admin';
import { ImportButton } from "react-admin-import-csv";
import QRCode from "react-qr-code";
import { v4 as uuidv4 } from 'uuid';
import CustomCurrencyField from "./CustomCurrencyField";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    DateInput,
    Button,
    DateField,
    SimpleForm,
    TextField,
    TextInput,
    NumberField,
    EditButton,
    DeleteButton, SelectArrayInput, ReferenceArrayInput, NumberInput
} from "react-admin";
import Card from "@mui/material/Card";
import {addYears, format} from "date-fns";

const ListActions = (props) => {
    const { className, basePath } = props;
    return (
        <TopToolbar className={className}>
            <CreateButton basePath={basePath} />
            <ImportButton {...props} />
            <ExportButton basePath={basePath} />
        </TopToolbar>
    );
};

export const GiftcardsList = props => (
    <List actions={<ListActions />} {...props}>
        <Datagrid rowClick="edit">
            <TextField label="Barcode" source="code" />
            <TextField label="Titel" source="voucherType" />
            <DateField label="Gutschein gültig bis" source="validUntil" />
            <CustomCurrencyField label="Anfangswert" source="amount" />
            <CustomCurrencyField label="Aktueller Wert" source="currentValue" />
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);

export const GiftcardsShow = (props) => {
    return (
        <Show {...props} >
            <Card sx={{minHeight: 650}}>
                <QRCode value={props.id}/>
            </Card>
        </Show>
    );
}

const dateTwoYears = format(addYears(new Date(), 2), "yyyy-MM-dd");

const barcode = uuidv4().toLowerCase();


const giftcardsDefaultValue = () => ({ validUntil: dateTwoYears, code: barcode, id: barcode});


const onImageDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = "QRCode";
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
};

const validateCodeCreation = (values) => {
    const errors = {};
    if (!values.amount) {
        errors.amount = ['Required'];
    }
    if (values.code.toLowerCase() !== values.code) {
        errors.code = 'Only lowercase letters allowed';
    }
    return errors
};



export const GiftcardsCreate = (props) => (
    <Create {...props} >
        <SimpleForm initialValues={giftcardsDefaultValue} validate={validateCodeCreation}>
            <TextInput source="code" label="Barcode" disabled/>
            <TextInput source="voucherType" label="Titel" helperText="Beschreibung des Gutscheins" />
            <DateInput source="validUntil" label="Gültig bis" />
            <NumberInput source="amount" label="Wert" helperText="Wert des Gutscheins in Rappen" />
        </SimpleForm>
    </Create>
);

/*Parkplatz für nicht verwendete Felder:
 <TextInput source="useCases" label="Anzahl Verwendungen"/>
 <TextInput source="history" />
  <TextInput label="Beschreibung" source="description" />
             <ReferenceArrayInput label="Einschränkung nach Produkten" source="products" reference="products">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ReferenceArrayInput label="Einschränkung nach Kategorien" source="categories" reference="categories">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
 */

export const GiftcardsEdit = (props) => {
    return (
    <Edit {...props}>
        <SimpleForm>
            <QRCode id="QRCode" value={props.id} />
            <Button onClick={onImageDownload} label="Download QR-Code" />
            <TextField label="Barcode" source="code"/>
            <TextInput source="code" label="Barcode" disabled/>
            <TextInput source="voucherType" label="Titel" helperText="Beschreibung des Gutscheins" />
            <DateInput source="validUntil" label="Gültig bis" />
            <NumberInput source="amount" label="Anfangswert" helperText="Anfangswert des Gutscheins in Rappen" />
            <NumberInput source="currentValue" label="Aktueller Wert" helperText="Wert des Gutscheins in Rappen" disabled />
        </SimpleForm>
    </Edit>
);
}


