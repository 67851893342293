import * as React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import CardWithIcon from './CardWithIcon';
import DollarIcon from "@material-ui/icons/AttachMoney";

import { Products } from '../types';

interface Props {
    products?: Products[];
}

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
}));


const ProductQuantity = (props: Props) => {
    let { products = [] } = props;

    products = products.sort((a, b) => (a.quantity > b.quantity ? -1 : 1)).slice(0, 5);
    const classes = useStyles();
    const translate = useTranslate();
    return (
            <CardWithIcon
                to="/products"
                icon={DollarIcon}
                title={"letzte 7 Tage"}
                subtitle={translate('pos.dashboard.product_quantity')}

            >

            <List dense={true}>
                {products.map(record => (
                    <ListItem
                        key={record.id}
                        button
                        component={Link}
                        to={`/products/${record.productId}`}
                    >

                        <ListItemText
                            primary={record.title}
                            secondary={record.quantity + " Stück"}

                        />
                        <ListItemSecondaryAction>
                            <span className={classes.cost}>
                                CHF {(record.price / 100).toFixed(2)}
                            </span>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List>
            </CardWithIcon>
    );
};

export default ProductQuantity;