// in src/posts.js
import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    SimpleShowLayout,
    CreateButton,
    TopToolbar,
    SimpleForm,
    NumberInput,
    ExportButton,
    TextField,
    TextInput,
    DateTimeInput,
    EditButton,
    DeleteButton,
    RichTextField,
    DateField,
    ImageInput,
   NumberField, BooleanInput, SelectArrayInput, Pagination, ReferenceManyField, SingleFieldList, ChipField, ReferenceArrayInput,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { ImportButton } from "react-admin-import-csv";
const productFilter = [
    <TextInput source="name" label="Search by Name" alwaysOn />,
    <NumberInput source="barcode" label="Search by Barcode" alwaysOn />,
];
const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;
const ListActions = (props) => {
    const { className, basePath } = props;
    return (
        <TopToolbar className={className}>
            <CreateButton basePath={basePath} />
            <ImportButton {...props} />
            <ExportButton basePath={basePath} />
        </TopToolbar>
    );
};
export const BundleList = (props) => (
    <List filters={productFilter} actions={<ListActions />} perPage={100} pagination={<PostPagination />} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="bundleName" />
            <NumberField source="price" />
            <TextField source="barcode" />
            <ReferenceManyField label="Products" reference="products" target="name">
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceManyField>

         {/*    <DateTimeInput source="createdate" />
            <DateTimeInput source="lastupdate" /> */}
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);

export const BundleShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField disabled source="id" />
            <TextField source="name" />
            <NumberField source="price" />
            <TextField source="barcode" />
            <RichTextInput source="Beschreibung" />
            <DateTimeInput disabled source="createdate" />
            <DateTimeInput disabled source="lastupdate" />
            <TextField source="createdby" />
            <TextField source="updatedby" />
            <RichTextField source="body" />
        </SimpleShowLayout>
    </Show>
);

export const BundleCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="bundleName" />
            <RichTextInput source="description" />
            <TextInput source="subtitle" />
            <ReferenceArrayInput source="name" reference="products">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <TextInput source="depositProduct" />
            <TextInput source="bundledProduct" />
            <BooleanInput source="outOfStock" />
            <BooleanInput source="deleted" />
            <TextInput source="taxCategory" />
            <BooleanInput source="weighByCustomer" />
            <TextInput source="referenceUnit" />
            <NumberInput source="price" />
            <NumberInput source="barcode" />
            <BooleanInput source="saleStop" />
            <TextInput source="scanSound" />
            <TextInput source="scanMessage" />
            <BooleanInput source="notForSale" />
            <BooleanInput source="isDiscountable" />
            <BooleanInput source="showOnScale" />
        </SimpleForm >
    </Create>
);

export const BundleEdit = (props) => (
    <Edit aside={<Aside />} {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="bundleName" />
            <RichTextInput source="description" />
            <TextInput source="subtitle" />
            <ReferenceArrayInput source="name" reference="products">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput>
            <ImageInput source="imageUrl" />
            <TextInput source="depositProduct" />
            <TextInput source="bundledProduct" />
            <BooleanInput source="outOfStock" />
            <BooleanInput source="deleted" />
            <TextInput source="taxCategory" />
            <BooleanInput source="weighByCustomer" />
            <TextInput source="referenceUnit" />
            <NumberInput source="price" />
            <NumberInput source="barcode" />
            <BooleanInput source="saleStop" />
            <TextInput source="scanSound" />
            <TextInput source="scanMessage" />
            <BooleanInput source="notForSale" />
            <BooleanInput source="isDiscountable" />
            <BooleanInput source="showOnScale" />
        </SimpleForm>
    </Edit>
);

const Aside = (props) => {
    return (
        <div style={{ width: 300, margin: '1em' }}>
            <SimpleShowLayout>
                <h3>History </h3>
                <DateField disabled source="createdate" />
                <DateField disabled source="lastupdate" />
                <TextField disabled source="createdby" />

                <TextField disabled source="updatedby" />
            </SimpleShowLayout>
        </div>
    );
}
