import {
    useDataProvider, useGetOne,
    useVersion,
} from "react-admin";
import Box from '@mui/material/Box';
import {useCallback, useEffect, useState} from "react";
import {subDays} from "date-fns";
import * as React from "react";
import {DataGrid, GridToolbar} from "@mui/x-data-grid";
import Paper from "@mui/material/Paper";
import {Chip, gridClasses} from "@mui/material";
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SupplierChipField, {getSupplier} from "./SupplierChipField";
import {useGetMany} from "ra-core";




const header = [
    {
        field: 'id',
        headerName: 'ID',
        type: 'text',
        width: 210,
        hide: true,
        editable: false,
    },
    {
        field: 'supplier',
        headerName: 'Lieferant',
        flex: 0.5,
        minWidth: 200,
        editable: false,
        valueFormatter: ( params ) => params.row.supplierName,
        renderCell: (params) => (
            <Chip
                label={<SupplierChipField record={params.value} />}
                component="a"
                href={`/#/supplier/${params.row.supplier}`}
                variant="outlined"
                clickable
            />
        )
    },
    {
        field: 'quantity',
        headerName: 'Verkaufte Produkte',
        type: 'number',
        width: 200,
        editable: false,
    },
    {
        field: 'price',
        headerName: 'Umsatz inkl. MWST',
        type: 'number',
        width: 200,
        editable: false,
        valueFormatter: ({ value }) => `${value / 100}`,
    },
    {
        field: 'margin',
        headerName: 'Marge',
        type: 'number',
        width: 150,
        hide: true,
        editable: false,
        valueFormatter: ({ value }) => `${value / 100}`,

    },
    {
        field: 'costPrice',
        headerName: 'Einstandspreis',
        type: 'number',
        width: 150,
        hide: true,
        editable: false,
        valueFormatter: ({ value }) => `${value / 100}`,

    },
    {
        field: 'tax',
        headerName: 'MWST',
        type: 'number',
        width: 120,
        hide: false,
        editable: false,
        valueFormatter: ({ value }) => `${value / 100}`,
    },
    {
        field: 'netPrice',
        headerName: 'Umsatz exkl. MWST',
        type: 'number',
        width: 200,
        hide: false,
        editable: false,
        valueFormatter: ({ value }) => `${value / 100}`,
    },
    {
        field: 'deposit',
        headerName: 'Deposit',
        type: 'number',
        width: 110,
        editable: false,
        hide: true,
        valueFormatter: ({ value }) => `${value / 100}`,
    },
];

export const SupplierAnalyticsList = () => {
    const aWeekAgo = subDays(new Date(), 7).setHours(0, 0, 0, 0);
    const today = new Date();
    const version = useVersion();
    const dataProvider = useDataProvider();
    const [start, setStart] = useState(new Date(aWeekAgo));
    const [end, setEnd] = useState(new Date(today));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();
    const [orderData, setData] = useState([]);
    const [supplierData, setSupplierData] = useState();
    const [sortModel, setSortModel] = React.useState([
        {
            field: 'quantity',
            sort: 'desc',
        },
    ]);

    const fetchOrders = useCallback(async (start, end) => {
        setData([]);
        setLoading(true);
        const recentOrders = await dataProvider.getList(
            "orders",
            {
                filter: { collectionQuery: c => c.where('orderDate', '>=', start).where('orderDate', '<=', end), paymentState: "successful" },
                sort: { field: 'orderDate', order: 'DESC' },
                pagination: { page: 1, perPage: 10000000 },
            }
        ).then(({ data }) => {
            const aggregations = data.reduce((acc, curr) =>
                acc.concat(curr.products), []
            );

            const soldProducts = aggregations.reduce((acc, curr) => {
                if (curr.productId.slice(0, 6) === "PPPPP_") {
                    curr.productId = curr.productId.slice(6, -18)
                }
                if (curr.type !== "product") {
                    return acc;
                }
                const product = acc.find(p => p.supplier === curr.supplier);
                if (product) {
                    product.quantity += curr.quantity;
                    product.price += curr.price;
                    product.netPrice += curr.netPrice;
                    product.tax += curr.tax;
                    product.deposit += curr.deposit;
                    if (curr.costPrice) {
                        product.margin += curr.price - curr.costPrice;
                    }
                    /*
                    if (curr.originalPrice) {
                        product.discount += curr.originalPrice - curr.price;
                    }
                     */

                } else {
                    curr.id = curr.supplier;
                    async function getSupplier(id) {
                        await dataProvider.getOne("supplier", {id: id})
                            .then(({data}) => {
                                return curr.supplierName = data.name
                            })
                    }
                    getSupplier(curr.supplier)
                    if (curr.costPrice) {
                        curr.margin = curr.price - curr.costPrice;
                    }
                    /*
                    if (curr.originalPrice) {
                        product.discount = curr.originalPrice - curr.price;
                    }

                     */
                    acc.push(curr);
                }
                return acc;
            }, []);
            setData(soldProducts);
            setLoading(false);
        })
            .catch(error => {
                setError(error);
                setLoading(false);
            });
    }, [dataProvider]);
    useEffect(() => {
        fetchOrders(start, end);
    }, [version, start, end, fetchOrders]);


    function handleChangeStart(e) {
        const newStart = new Date(e);
        newStart.setHours(0, 0, 0, 0);
        setStart(newStart);
    }
    function handleChangeEnd(e) {
        const newEnd = new Date(e);
        newEnd.setHours(23, 59, 59, 999);
        setEnd(newEnd);
    }
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div >

                    <Box sx={{ display: 'flex', marginBottom: 2, marginTop: 2 }}>
                        <Box sx={{marginRight: 2 }}>
                            <DatePicker

                                label="Von Datum"
                                value={start}
                                inputFormat="dd/MM/yyyy"
                                onChange={handleChangeStart}
                                renderInput={(params) => <TextField {...params} />}
                            /></Box>
                        <DatePicker
                            label="bis zu Datum"
                            value={end}
                            inputFormat="dd/MM/yyyy"
                            onChange={handleChangeEnd}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Box>



                    <Box component={Paper} >
                        <DataGrid
                            components={{
                                Toolbar: GridToolbar,
                            }}
                            rows={orderData}
                            columns={header}
                            loading={loading}
                            error={error}
                            sortingOrder={['desc', 'asc']}
                            sortModel={sortModel}
                            onSortModelChange={(model) => setSortModel(model)}
                            autoHeight
                            checkboxSelection
                            disableSelectionOnClick
                        >

                        </DataGrid>

                    </Box>
                </div>



        </LocalizationProvider>

    );
};
