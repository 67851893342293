import { useGetOne } from 'react-admin';
import * as React from "react";


const SupplierChipField = (record) => {
    const {data, loading, error} = useGetOne('supplier', record.record);
    if (loading) {
        return <p>Loading... </p>;
    }
    if (error) {
        return <p>Lieferant nicht gefunden</p>;
    }
    return data ? data.name : '';

};
export default SupplierChipField;