import * as React from "react";
import CustomCurrencyField from "./CustomCurrencyField";
import CustomPercentageField from "./CustomPercentageField";
import {
    Datagrid,
    List,
    Show,
    Create,
    ArrayInput,
    SimpleFormIterator,
    Edit,
    TabbedForm,
    FormTab,
    SingleFieldList,
    ChipField,
    FilterButton,
    SimpleShowLayout,
    ArrayField,
    SimpleForm,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    TextField,
    TextInput,
    NumberField,
    DeleteButton,
    SelectInput,
    DateInput,
    DateField,
    TopToolbar,
    ExportButton,
    useRecordContext, ImageInput, ImageField,
} from "react-admin";
import Button from '@mui/material/Button';
import {subDays} from "date-fns";
import {instanz} from "./App";

//const PostPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;


const orderFilter = [
        <SelectInput source="paymentState" label="Zahlungsstatus" alwaysOn choices={[
            { id: 'timeout', name: 'timeout' },
            { id: 'failed', name: 'failed' },
            { id: 'pending', name: 'pending' },
            { id: 'successful', name: 'successful' },
            { id: 'cancelled', name: 'cancelled' }
        ]} />,
        <SelectInput source="paymentMethod" label="Bezahlmethode" alwaysOn choices={[
            { id: 'PayTecTerminal', name: 'PayTec Terminal' },
            { id: 'cash', name: 'Bargeld' },
            { id: 'giftcard', name: 'Geschenkkarte' },

        ]} />,
    <SelectInput source="paymentBrand" label="Bezahlart" alwaysOn choices={[
        { id: 'Visa payWave', name: 'Visa payWave' },
        { id: 'V PAY', name: 'V PAY' },
        { id: 'TWINT', name: 'TWINT' },
        { id: 'Mastercard', name: 'Mastercard' },
        { id: 'Postcard', name: 'Postcard' },
        { id: 'Maestro-CH', name: 'Maestro-CH' },
    ]} />,
    ]
;

const ListActions = (props) => (
    <TopToolbar>
        <FilterButton/>
        <ExportButton maxResults={10000}/>
    </TopToolbar>
);

const aWeekAgo = subDays(new Date(), 7);
aWeekAgo.setHours(0, 0, 0, 0);
const today = new Date();

export const OrderList = (props) => (
    <div>
    {instanz === "toggenburgshop" ?
        <List  actions={<ListActions />} filters={orderFilter}  filter={{ collectionQuery: c => c.where('orderDate', '>=', aWeekAgo).where('orderDate', '<=', today) }} sort={{ field: 'orderDate', order: 'DESC' }}  {...props}>
            <Datagrid rowClick="show">
                <DateField source="orderDate" showTime label="Datum" />
                <CustomCurrencyField source="price" label="Total"  />
                <TextField source="paymentMethod" label="Bezahlmethode"  />
                <TextField source="paymentState" label="Bezahlstatus" />
                <TextField source="paymentBrand" label="Bezahlart" />
                <ArrayField source="products" label="Produkte" >
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ArrayField>
                <DeleteButton label="" redirect={false}/>
            </Datagrid>
        </List>
            :
        <List  actions={<ListActions />} filters={orderFilter} sort={{ field: 'orderDate', order: 'DESC' }}  {...props}>
            <Datagrid rowClick="show">
                <DateField source="orderDate" showTime label="Datum" />
                <CustomCurrencyField source="price" label="Total"  />
                <TextField source="paymentMethod" label="Bezahlmethode"  />
                <TextField source="paymentState" label="Bezahlstatus" />
                <TextField source="paymentBrand" label="Bezahlmethode" />
                <ArrayField source="products" label="Produkte" >
                    <SingleFieldList>
                        <ChipField source="title" />
                    </SingleFieldList>
                </ArrayField>
                <DeleteButton label="" redirect={false}/>
            </Datagrid>
        </List>
            }
    </div>);

export const OrderShow = (props) => {
    return (
        <Show aside={<Aside />} {...props} >
            <SimpleShowLayout>
                    <ArrayField sx={{maxWidth: "100%"}} label="Produkte" source="products">
                        <Datagrid sx={{maxWidth: "100%"}}>
                            <NumberField label="Anzahl" source="quantity" />
                            <TextField source="title" />
                            <ReferenceField label="Kategorie"  source="category" reference="categories">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField label="Lieferant" source="supplier" reference="supplier">
                                <TextField source="name" />
                            </ReferenceField>
                            <CustomCurrencyField label="Preis inkl. MWST" source="price" />
                            <CustomCurrencyField label="Preis exkl. MWST" source="netPrice" />
                            <CustomCurrencyField label="MWST" source="tax" />
                            <CustomPercentageField label="MWST-Satz" source="taxRate" />
                        </Datagrid>
                    </ArrayField>
            </SimpleShowLayout>
        </Show>
    )
}




export const OrderCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="name" />
            <TextInput source="brand" />
            <NumberInput source="price" />
            <DateInput disabled source="orderDate" />
        </SimpleForm>
    </Create>
);

export const OrderEdit = (props) => (
    <Edit aside={<Aside />} {...props}>
        <TabbedForm>
            <FormTab label="Zusammenfassung">
                <TextField source="paymentMethod" />
                <TextField source="orderId" />
                <TextField source="paymentState" />
                <NumberField source="price" />

            </FormTab>
            <FormTab label="products">
                <ArrayInput sx={{maxWidth: "100%"}} label="Produkte"  source="products">
                    <SimpleFormIterator sx={{maxWidth: "100%"}}>

                        <NumberInput label="Anzahl" source="quantity" />
                        <TextInput source="title" label="Produkt" disabled/>
                        <ReferenceInput label="Kategorie" perPage={100} source="category" reference="categories">
                            <SelectInput source="name" />
                        </ReferenceInput>
                        <ReferenceInput label="Lieferant" perPage={100} source="supplier" reference="supplier">
                            <SelectInput source="name" />
                        </ReferenceInput>
                        <NumberInput label="Deposit" source="deposit" />
                        <NumberInput label="Preis inkl. MWST" source="price" />
                        <NumberInput label="Preis exkl. MWST" source="netPrice" />
                        <NumberInput label="MWST" source="tax" />
                        <NumberInput label="MWST-Satz" source="taxRate" />
                    </SimpleFormIterator>
                </ArrayInput>

            </FormTab>
        </TabbedForm>
    </Edit>

);

const Aside = (props) => (
    <div style={{ width: 300, margin: '1em' }} {...props}>
        <SimpleShowLayout>
            <TextField disabled source="id" label="Bestellnummer" />
            <TextField source="paymentMethod" label="Bezahlmethode" />
            <TextField source="paymentState" label="Bezahlstatus" />
            <DateField disabled source="orderDate" label="Bestelldatum" showTime/>
            <DateField disabled source="transactionDate" label="Transaktionsdatum" showTime/>
            <ReceiptButton />
        </SimpleShowLayout>
    </div>
);



const ReceiptButton = () => {
    const record = useRecordContext();
    if (!record) return null;
    return <Button sx={{marginTop: 1}} target="_blank" variant="contained" href={'https://stockberg.scango.ch/?instance=' + record.instance + '&receipt=' + record.orderId + '&store=' + record.store} >Beleg anzeigen</Button>
   ;
};




// <TextField disabled source="transactionMessage" />