import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    BooleanField,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    TextInput,
    EditButton,
    DeleteButton,
} from "react-admin";

export const PaymentmethodsList = props => (
    <List {...props}>
        <Datagrid rowClick="edit">
            <TextField source="instanz" />
            <TextField source="type" />
            <BooleanField source="active" />
            <EditButton label="" />
            <DeleteButton label="" redirect={false}/>
        </Datagrid>
    </List>
);

export const PaymentmethodsShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="api" />
            <TextField source="instanz" />
            <TextField source="type" />
            <BooleanField source="active" />
        </SimpleShowLayout>
    </Show>
);

export const PaymentmethodsCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="api" />
            <TextInput source="instanz" />
            <TextInput source="type" />
            <BooleanField source="active" />
        </SimpleForm>
    </Create>
);

export const PaymentmethodsEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="api" />
            <TextInput source="instanz" />
            <TextInput source="type" />
            <BooleanField source="active" />
        </SimpleForm>
    </Edit>
);
